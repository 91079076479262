<div class="flex gap-4">
  @if (user$ | async; as user) {
    @if ((isSmallerThanMedium$ | async) === false) {
      <div class="sidebar-container px-4 min-w-[23%]">
        <div class="flex items-center m-4">
          <atom-icon
            iconId="atom:core:user"
            class="shrink-0 text-3xl mx-2"></atom-icon>
          <p class="font-semibold userName">
            {{ user.firstName }} {{ user.lastName }}
          </p>
        </div>
        <ul class="navigation">
          <!-- <li routerLink="/user/profile" routerLinkActive="active">
            <div class="px-4">
              <a class="navItems">My Profile</a>
            </div>
          </li> -->
          @if (isAdmin$ | async) {
            <li routerLink="/user/admin/users" routerLinkActive="active">
              <div class="px-4">
                <a class="navItems">User Management</a>
              </div>
            </li>
            <li routerLink="/user/admin/products" routerLinkActive="active">
              <div class="px-4">
                <a class="navItems">Product Management</a>
              </div>
            </li>
            <li routerLink="/user/admin/orders" routerLinkActive="active">
              <div class="px-4">
                <a class="navItems">Order Management</a>
              </div>
            </li>
          }
          <a class="logout navItems px-6" (click)="onLogoutAttempt()">Logout</a>
        </ul>
      </div>
    }

    <div class="main-container grow p-4">
      <router-outlet></router-outlet>
    </div>
  } @else {
    <atom-progress-spinner class="mt-5"></atom-progress-spinner>
  }
</div>
